import { computed, effect, inject, Injectable, OnInit, signal, Signal } from '@angular/core';
import { CartService, ShippingService, StoreService } from '@bc-core/api-services/api-us-services';
import { DeliveryOption, OrderShippingDto } from '@bc-core/new-models/us-models';
import { Store, VoucherType } from '@bc-libs/core';
import { Observable, of } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractDeliveryServiceListComponent implements OnInit {
  isLoaded: Signal<boolean>;
  deliveryOptions: Signal<DeliveryOption[]>;
  selectedId = signal<string>(null);
  selectedOption: Signal<DeliveryOption>;
  isSaving = signal(false);
  store: Signal<Store>;

  #cartService = inject(CartService);
  #storeService = inject(StoreService);
  #shippingService = inject(ShippingService);

  protected abstract showPaidDeliveryConfirmationModal(): Observable<boolean>;

  protected abstract showDutiesAndTaxesModal(): void;

  protected abstract showEmptyDutiesAndTaxesModal(): void;

  protected abstract navigateToNote(): void;

  protected constructor() {
    this.isLoaded = this.#shippingService.deliveryQuoteLoaded;
    this.deliveryOptions = computed(() => this.#shippingService.deliveryQuote()?.shippingQuotes);
    this.store = this.#storeService.current;

    this.selectedOption = computed(() =>
      this.deliveryOptions()?.find(o => o.reference === this.selectedId())
    );

    effect(() => {
      const option = this.selectedOption();

      if (option?.type !== 'INTERNATIONAL') {
        return;
      }

      option.estimatedImportDutiesAmount ? this.showDutiesAndTaxesModal() : this.showEmptyDutiesAndTaxesModal()
    });
  }

  ngOnInit(): void {
    this.#shippingService.initDeliveryQuote()
      .subscribe();
  }

  select(id: string): void {
    this.selectedId.set(id);
  }

  next(): void {
    const order = this.#cartService.current();
    const options = this.deliveryOptions();
    const option = this.selectedOption();
    const { voucher } = order;

    const haveFreeOption = options.some(opt => opt.isFree);

    const confirmPaidDelivery$ = haveFreeOption && voucher.type === VoucherType.FreeLocalDelivery && !option.isFree
      ? this.showPaidDeliveryConfirmationModal()
      : of(true);

    confirmPaidDelivery$
      .pipe(
        filter(result => !!result),
        switchMap(() => {
          this.isSaving.set(true);

          return this.#cartService.addDeliveryData(this.prepareData());
        }),
        finalize(() => this.isSaving.set(false)),
      )
      .subscribe(() => {
        this.navigateToNote();
      });
  }

  private prepareData(): OrderShippingDto {
    const option = this.selectedOption();

    return {
      accountId: option.accountId,
      reference: option.reference,
    };
  }
}
