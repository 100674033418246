import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { EventEmitter, Injectable, Output, inject } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { DestroyComponent } from '../../common/destroy/destroy.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { FormArray, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { cookies } from './cookies-config';

@Injectable()
export abstract class AbstractCookiesComponent extends DestroyComponent {
  @Output() cookiesAccepted = new EventEmitter<string[]>();
  cookies = cookies;
  acceptAllLabel: string;
  isMobile: boolean;
  cookiePolicyUrl: string;
  config: Object;
  form: UntypedFormGroup;

  breakpointObserver = inject(BreakpointObserver);
  dialog = inject(MatDialog);
  fb = inject(FormBuilder);
  dialogRef = inject(MatDialogRef<ComponentType<any>>);

  //TODO find a way to replace any
  component: ComponentType<any>;

  constructor(
  ) {
    super();

    this.createForm();

    this.breakpointObserver.observe(Breakpoints.XSmall)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(result => {
        this.isMobile = result.matches;
        this.acceptAllLabel = this.isMobile ? $localize`Accept all` : $localize`Accept all cookies`;
        this.config = this.isMobile ?
            { width: '100vw', maxWidth: '100vw', minWidth: '100vw', maxHeight: '95vh', position: { bottom: '0' } } : 
            { width: '624px', maxWidth: '95vw', maxHeight: '95vh', };
      });
  }

  get cookiesArray(): FormArray {
    return this.form.get('cookies') as FormArray;
  }

  acceptAllAndEmit(): void {
    const acceptedCookies = this.cookies.map(cookie => cookie.type);
    this.cookiesAccepted.emit(acceptedCookies);
  }

  settings(): void {
    this.dialog.open(this.component, { ...this.config, data: { cookies: this.cookies, isMobile: this.isMobile } })
      .afterClosed()
      .pipe(
        filter(result => !!result),
      )
      .subscribe(cookies => this.cookiesAccepted.emit(cookies));
  }

  acceptAllAndClose(): void {
    const acceptedCookies = this.cookies.map(cookie => cookie.type);
    this.dialogRef.close(acceptedCookies);
  }

  onSubmit(): void {
    const acceptedCookies = this.cookies.map((cookie, index) => ({
      ...cookie,
      checked: this.cookiesArray.at(index).value
    }))
    .filter(cookie => cookie.checked)
    .map(cookie => cookie.type);

    this.dialogRef.close(acceptedCookies);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private createForm(): void {
    const cookieControls = this.cookies.map(cookie => this.fb.control(cookie.checked));

    this.form = this.fb.group({
      cookies: this.fb.array(cookieControls),
    });
  }
}