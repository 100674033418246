import { computed, inject, Injectable, OnDestroy, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CartService, StoreService } from '@bc-core/api-services/api-us-services';
import { Order } from '@bc-core/new-models/us-models';
import { Store } from '@bc-libs/core';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractCheckoutComponent implements OnDestroy {
  abstract id: Signal<string>;
  isLoaded: Signal<boolean>;
  order: Signal<Order>;
  store: Signal<Store>;
  isOrderExpired: Signal<boolean>;

  #router = inject(Router);
  #cartService = inject(CartService);
  #storeService = inject(StoreService);

  ngOnDestroy(): void {
    this.#cartService.purgeCheckout();
  }

  protected constructor() {
    this.isLoaded = computed(() => this.#cartService.currentLoaded() && this.#storeService.currentLoaded());
  }

  protected initOrder(): void {
    this.order = this.#cartService.current;
    this.isOrderExpired = this.#cartService.isCurrentOrderExpired;

    toObservable(this.id)
      .pipe(
        tap(id => {
          if (id) {
            this.#cartService.loadShippingSettings(id);
          }
        }),
        filter(() => !this.#cartService.currentLoaded()),
        switchMap(id => {
          // load order for logged user or for guest
          if (id) {
            return this.#cartService.findAndSetCurrentSync(id);
          }

          return this.#storeService.verifyAndSetCart();
        }),
        catchError(resp => {
          this.navigateToCatalog();
          return resp;
        }),
      )
      .subscribe((order: Order) => {
        if ([ 'Confirmed', 'Completed' ].includes(order.orderStatus)) {
          this.navigateToComplete(order);
          return;
        }

        if ([ 'Cancelled', 'Expired' ].includes(order.orderStatus)) {
          // todo create order expired page
          this.navigateToNotValid();
          return;
        }

        // if order is pending or if it's guest order
        if (order.orderStatus === 'Pending' || !order.id) {
          return;
        }

        this.navigateToCatalog();
      });
  }

  private navigateToCatalog(): void {
    this.#router.navigate([ 'catalog' ]);
  }

  private navigateToNotValid(): void {
    this.#router.navigate([ 'order-not-valid' ]);
  }

  private navigateToComplete(order: Order): void {
    this.#router.navigate([ 'checkout', order.id, 'complete' ]);
  }
}
