import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CartService } from '@bc-core/api-services/api-us-services';
import { GuestService } from '@bc-core/new-services/us-services';
import { FormService } from '@bc-libs/core';
import { finalize, switchMap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractGuestSignUpComponent {
  form: FormGroup;
  isSaving = signal(false);

  #fb = inject(FormBuilder);
  #router = inject(Router);
  #authService = inject(AuthService);
  #cartService = inject(CartService);
  #guestService = inject(GuestService);
  #formService = inject(FormService);

  protected abstract openExistingAccountDialog(): void;
  protected abstract navigateToCheckout(orderId: string): void;

  protected constructor() {
    this.createForm();
  }


  back(): void {
    this.#router.navigate([ '/' ]);
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.isSaving.set(true);

    const formData = this.form.value;

    this.#guestService.data = formData;

    this.#authService.signUpGuest(formData)
      .pipe(
        switchMap(() => this.#cartService.saveOrder(this.#cartService.current())),
        finalize(() => this.isSaving.set(false)),
      )
      .subscribe(order => {
          this.#cartService.setCurrent(order);
          this.navigateToCheckout(order.id);
        },
        (resp: HttpErrorResponse) => {
          this.#formService.processErrors(resp, this.form);

          if (resp.status === 400 && !resp.error.errors) {
            this.openExistingAccountDialog();
          }
        },
      );
  }

  private createForm() {
    const user = this.#guestService.user();

    this.form = this.#fb.group({
      email: [ user.email || '', [ Validators.required, Validators.email ] ],
      firstName: [ user.firstName || '', Validators.required ],
      lastName: [ user.lastName || '', Validators.required ],
      mobileNumber: [ user.mobileNumber || '', Validators.required ],
    });
  }
}
