import { computed, EventEmitter, inject, Injectable, OnInit, Output, signal, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MapGeocoder } from '@angular/google-maps';
import { StoreService } from '@bc-core/api-services/api-us-services';
import { AddressOption } from '@bc-core/new-models/us-models';
import { Address, AddressFormService, ConfigService, CountryConfig, Store } from '@bc-libs/core';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DestroyComponent } from '../../common/destroy/destroy.component';

class MapMarker {
  position?: google.maps.LatLngLiteral;
  options: google.maps.MarkerOptions;
}

@Injectable()
export abstract class AbstractFullAddressFormComponent extends DestroyComponent implements OnInit {
  abstract address: Signal<Address>;
  @Output() saved = new EventEmitter<AddressOption>();
  form: FormGroup;
  requiredFields: Array<keyof Address> = [ 'addressLine1', 'countryCode', 'latitude', 'longitude' ];
  disabledFields: Array<keyof Address> = [ 'city', 'country', 'province', 'suburb', 'latitude', 'longitude' ];
  addressSearchControl = new FormControl();
  isSaving: boolean;
  mapOptions = signal<google.maps.MapOptions>({
    zoom: 8,
    mapTypeControl: false,
  });
  mapMarker: MapMarker = {
    options: {
      draggable: false,
    }
  };

  #address: Signal<Address>;
  // todo find a better way to update the form
  address$: Observable<Address>;

  #addressFormService = inject(AddressFormService);
  #geocoder = inject(MapGeocoder);
  #configService = inject(ConfigService);
  #storeService = inject(StoreService);

  protected constructor() {
    super();

    this.#address = computed(() => {
      const address = this.address();

      return {
        ...address,
        country: this.#configService.countries.find(c => c.iso2 === address.countryCode)?.name
      }
    });

    this.addAddressForm();

    this.address$ = toObservable(this.#address);
  }

  ngOnInit(): void {
    const store = this.#storeService.current();
    const countries = this.#configService.$countries();
    const mapOptions = this.mapOptions();

    if (store && countries.length > 0 && !mapOptions.center) {
      this.setMapCenter(store, countries);
    }

    this.address$
      .pipe(
        filter(address => !!address)
      )
      .subscribe(address => this.resetForm(address));
  }

  onClearSearchClick(): void {
    this.mapMarker.position = null;
    this.addressSearchControl.reset();

    this.form.reset();
    this.form.markAsDirty();
    this.form.markAllAsTouched();
  }

  onMapClick(event: google.maps.MapMouseEvent): void {
    this.mapMarker.position = event.latLng.toJSON();

    this.#geocoder.geocode({ location: event.latLng })
      .pipe(
        filter(resp => resp.status === 'OK')
      )
      .subscribe(resp => {
        const place = resp.results.find(({ types }) => types.includes('street_address') || types.includes('route')) ?? resp.results[ 0 ];
        const address = this.#addressFormService.getAddressFromGooglePlace(place);

        this.updateForm(address);
      });
  }

  updateForm(address: Address): void {
    this.form.patchValue(address);
    this.addressSearchControl.setValue(address.formattedAddress);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
  }

  updateMap(): void {
    const { latitude, longitude, formattedAddress } = this.form.getRawValue();

    if (!latitude || !longitude) {
      return;
    }

    const latLngLiteral: google.maps.LatLngLiteral = { lat: +latitude, lng: +longitude };

    this.mapOptions.set({
      zoom: 15,
      center: latLngLiteral,
    });

    this.mapMarker.position = latLngLiteral;
    this.addressSearchControl.setValue(formattedAddress);
  }

  updateFormattedAddress(): void {
    const data = this.form.getRawValue();

    const formattedAddress = [
      data.addressLine1,
      data.addressLine2,
      data.city,
      data.province,
      data.postalCode,
      data.country
    ]
      .filter(v => !!v)
      .join(', ');

    this.form.patchValue({ formattedAddress }, { emitEvent: false });
  }

  onSubmit(): void {
    this.saved.emit({ ...this.address, ...this.form.getRawValue() });
  }

  private addAddressForm(): void {
    this.form = this.#addressFormService.getFormGroup();
    //this.form.addControl('friendlyName', this.fb.control('', Validators.required));

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateMap();
        this.updateFormattedAddress();
      });

    this.requiredFields.forEach(f => this.form.get(f).setValidators(Validators.required));
    this.disabledFields.forEach(f => this.form.get(f).disable());
  }

  private resetForm(address: Address): void {
    this.form.reset(address);
    this.addressSearchControl.setValue(address.formattedAddress);
  }

  private setMapCenter(store: Store, countries: CountryConfig[]): void {
    const country = {
      code: store.country,
      name: countries.find(c => c.iso2 === store.country).name,
    };

    this.#addressFormService.getMapCenter(country)
      .subscribe(center => {
        this.mapOptions.update(options => ({ ...options, center }));
      })
  }
}
