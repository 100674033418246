import { inject, Injectable, Signal, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CartService } from '@bc-core/api-services/api-us-services';
import { Order, OrderShippingDto } from '@bc-core/new-models/us-models';
import { finalize } from 'rxjs/operators';

@Injectable()
export abstract class AbstractDeliveryNoteComponent {
  form: FormGroup;
  order: Signal<Order>;
  isSaving = signal(false);

  #fb = inject(FormBuilder);
  #cartService = inject(CartService);

  protected abstract navigateToPayment(): void;

  protected constructor() {
    this.order = this.#cartService.current;
    this.createForm();
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    if (this.form.pristine) {
      this.navigateToPayment();
      return;
    }

    this.isSaving.set(true);

    this.#cartService.addDeliveryData(this.prepareData())
      .pipe(
        finalize(() => this.isSaving.set(false)),
      )
      .subscribe(newOrder => this.navigateToPayment());
  }

  private createForm(): void {
    this.form = this.#fb.group({
      note: '',
    });
  }

  private prepareData(): OrderShippingDto {
    const order = this.#cartService.current();
    const { shippingDetails: { accountId, reference } } = order;
    const formData = this.form.value;

    return {
      accountId,
      reference,
      note: formData.note,
    };
  }
}
