import { Injectable, signal } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

type hookFn = (value?: boolean) => void;

@Injectable()
export class AbstractCheckboxComponent implements ControlValueAccessor {
  //@Input() color: BoxThemePalette = 'green';
  onChange: hookFn;
  onTouched: hookFn;
  disabled: boolean;
  checked = signal(false);

  constructor() { }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement;

    this.checked.set(target.checked);

    if (this.onChange) {
      this.onChange(this.checked());
    }
  }

  writeValue(checked: boolean): void {
    this.checked.set(checked);
  }

  registerOnChange(fn: hookFn) { this.onChange = fn; }

  registerOnTouched(fn: hookFn) { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
