export class Cookie {
  title: string;
  description: string
  canBeSwitchedOff: boolean;
  toggle: boolean;
  checked: boolean;
  expanded: boolean;
  type: 'essential_cookies' | 'performance_cookies' | 'functionality_cookies'
}

export const cookies: Cookie[] = [
    {
      title: $localize`Essential cookies`,
      description: $localize`Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts, and payment processing.<br /><br />We use essential cookies to enable certain functions on our website.`,
      canBeSwitchedOff: false,
      toggle: true,
      checked: true,
      expanded: false,
      type: 'essential_cookies'
    },
    {
      title: $localize`Performance cookies`,
      description: $localize`Performance cookies track how you use a website during your visit. Typically, this information is anonymous and aggregated, with information tracked across all site users. They help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience's overall website experience. These cookies may be set by the website you're visiting (first-party) or by third-party services. They do not collect personal information about you.<br /><br />We use performance cookies on our site.`,
      canBeSwitchedOff: true,
      toggle: true,
      checked: false,
      expanded: false,
      type: 'performance_cookies'
    },
    {
      title: $localize`Functionality cookies`,
      description: $localize`Functionality cookies are used to collect information about your device and any settings you may configure on the website you're visiting (like language and time zone settings). With this information, websites can provide you with customised, enhanced, or optimised content and services. These cookies may be set by the website you're visiting (first-party) or by third-party services.<br/><br />We use functionality cookies on our site.`,
      canBeSwitchedOff: true,
      toggle: true,
      checked: false,
      expanded: false,
      type: 'functionality_cookies'
    },
  ];